// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';

// Add the Firebase products that you want to use
import 'firebase/firestore';
import "firebase/auth";

const config = {
    apiKey: 'AIzaSyA3utN94XyS4_S9QsS8BVfDLSe8dzpz8-w',
    authDomain: 'juliablogfavourites.firebaseapp.com',
    databaseURL: 'https://juliablogfavourites.firebaseio.com',
    projectId: "juliablogfavourites",
    storageBucket: "juliablogfavourites.appspot.com",
    messagingSenderId: "170215086210",
    appId: "1:170215086210:web:03ede18df1130ae94ebc9b"
};
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}
export default firebase