import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import firebase from '../services/firebase';
import 'firebase/database';

const shockwave = keyframes`
  0% {
    box-shadow:0 0 rgba(46, 139, 192,0.1);
  }
  70% {
    box-shadow:0 0 5px 10px rgba(46, 139, 192, 0.75);
  }
  100% {
    box-shadow:0 0 0 0 rgba(46, 139, 192,0.75);
  }
`;

const ClapButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: auto;
`;

const ClapButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: 1px solid white;
  background: rgb(46, 139, 192,0.7);
  transition-delay: 20ms;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 2px #0000FF;
    animation: ${shockwave} 2s infinite;
  }

  &:active {
    box-shadow: none;
  }

  ${props =>
    props.isClapping &&
    css`
      border: 3px solid white;
      box-shadow: none;
      transform: scale(1.2);
    `}
`;

const HandIcon = styled.span`
  width: 30px;
  font-size: 3.5rem;
  color: white;
  ${props =>
    props.clapped &&
    css`
    color: white;
    `}
`;

const ClapCount = styled.div`
  font-size: 16px;
  color: white;
  display: flex;
  transition-delay: 300ms;
  ${props =>
    props.countColor &&
    css`color:
    ${props.countColor}`
  }
`;

const ClapButtonContainer = ({ slug, color }) => {
  let countRef = firebase.database().ref(`/applause/${slug}`)

  const [claps, setClaps] = useState({
    userClaps: 0,
    totalClaps: 0,
    isClapped: false,
    isClapping: false,
    clapsInARow: 0
  });

  const clapButton = React.useRef();

  const handleClap = () => {
    countRef.once('value').then(function (snapshot) {
      const count = snapshot.val()
      countRef.set(count + 1)
    })
  }

  const getApplause = () => {
    countRef.on('value', function (snapshot) {
      let val = snapshot.val() ? snapshot.val() : 0
      setClaps({
        ...claps,
        userClaps: claps.userClaps + 1,
        totalClaps: val,
        isClapped: true,
        isClapping: false
      });
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(getApplause);

    // Unsubscribe on cleanup
    return () => unsubscribe();
  }, []);

  const handleClapping = e => {
    e.preventDefault();
    handleClap();
  };

  return (
    <ClapButtonWrap>
      <ClapButton
        onClick={handleClapping}
        isClapping={claps.isClapping}
        clapped={claps.isClapped}
        ref={clapButton}
      >
        <HandIcon role="img" aria-label="clap" clapped={claps.isClapped}>
          <svg
            id="clap--icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-549 338 100.1 125"
            style={{ fill: claps.isClapped ? "white" : "white" }}
          >
            <path d="M-471.2 366.8c1.2 1.1 1.9 2.6 2.3 4.1.4-.3.8-.5 1.2-.7 1-1.9.7-4.3-1-5.9-2-1.9-5.2-1.9-7.2.1l-.2.2c1.8.1 3.6.9 4.9 2.2zm-28.8 14c.4.9.7 1.9.8 3.1l16.5-16.9c.6-.6 1.4-1.1 2.1-1.5 1-1.9.7-4.4-.9-6-2-1.9-5.2-1.9-7.2.1l-15.5 15.9c2.3 2.2 3.1 3 4.2 5.3zm-38.9 39.7c-.1-8.9 3.2-17.2 9.4-23.6l18.6-19c.7-2 .5-4.1-.1-5.3-.8-1.8-1.3-2.3-3.6-4.5l-20.9 21.4c-10.6 10.8-11.2 27.6-2.3 39.3-.6-2.6-1-5.4-1.1-8.3z" />
            <path d="M-527.2 399.1l20.9-21.4c2.2 2.2 2.7 2.6 3.5 4.5.8 1.8 1 5.4-1.6 8l-11.8 12.2c-.5.5-.4 1.2 0 1.7.5.5 1.2.5 1.7 0l34-35c1.9-2 5.2-2.1 7.2-.1 2 1.9 2 5.2.1 7.2l-24.7 25.3c-.5.5-.4 1.2 0 1.7.5.5 1.2.5 1.7 0l28.5-29.3c2-2 5.2-2 7.1-.1 2 1.9 2 5.1.1 7.1l-28.5 29.3c-.5.5-.4 1.2 0 1.7.5.5 1.2.4 1.7 0l24.7-25.3c1.9-2 5.1-2.1 7.1-.1 2 1.9 2 5.2.1 7.2l-24.7 25.3c-.5.5-.4 1.2 0 1.7.5.5 1.2.5 1.7 0l14.6-15c2-2 5.2-2 7.2-.1 2 2 2.1 5.2.1 7.2l-27.6 28.4c-11.6 11.9-30.6 12.2-42.5.6-12-11.7-12.2-30.8-.6-42.7m18.1-48.4l-.7 4.9-2.2-4.4m7.6.9l-3.7 3.4 1.2-4.8m5.5 4.7l-4.8 1.6 3.1-3.9" />
          </svg>
        </HandIcon>
      </ClapButton>
      <ClapCount isClapping={claps.isClapping} countColor={color}>{claps.totalClaps}</ClapCount>
    </ClapButtonWrap>
  )
}

export default ClapButtonContainer;
