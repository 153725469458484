import React, { useEffect, useState } from "react"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#2e8bc0',
        backgroundColor: 'white'
    },
}));

export default function SimpleBackdrop({ load, setLoad }) {

    // const [loadingIndicator, setLoadingIndicator] = useState(true)

    useEffect(() => {
        setTimeout(function () {
            setLoad(false)
        }, 1000)
    }, [])

    const classes = useStyles();

    return (
        <div>
            <Backdrop className={classes.backdrop} open={true} >
                <div>
                    <CircularProgress color="inherit" />
                </div>
            </Backdrop>
        </div>
    );
}
