import React, { useEffect, useState } from "react"
import Backdrop from '@material-ui/core/Backdrop';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
//import comingSoonImg from '../../static/comingSoon.png'
import comingSoonImg from '../../static/comingSoonHeaderNew.png'
import Popover from '@material-ui/core/Popover';
import Subscribe from '../components/subscribeHubspot'
import { Typography } from '@material-ui/core';
import youtubeSubscribe from '../../static/youtubeSubscribe.png'

import profilePic from '../../content/assets/profile-pic-real.jpg'

const useStyles = makeStyles((theme) => ({
    'subscribe': {
        backgroundColor: 'transparent'
    },
    btn: {
        [theme.breakpoints.up('xs')]: {
            top: '52%',
            right: '14%',
            fontSize: '8px'
        },
        [theme.breakpoints.up('sm')]: {
            top: '54.3%',
            right: '15.5%',
            fontSize: '15px'
        },
        [theme.breakpoints.up('md')]: {
            top: '56%',
            right: '16.5%',
            fontSize: '18px'
        },
        [theme.breakpoints.up('lg')]: {
            top: '67%',
            right: '24%',
            fontSize: '18px'
        },
        position: 'absolute',
        backgroundColor: '#00cc64',
        color: 'black',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#16D5DF',
            color: 'black'
        },
        boxShadow: '0 3px 3px rgba(0, 0, 0, 0.4)',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#2e8bc0',
        backgroundColor: 'rgba(0, 0, 0,0.9)',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'rgba(0, 0, 0,0.94)',
        }
    },
    youtubeImage: {
        cursor: 'pointer',
        [theme.breakpoints.up('xs')]: {
            position: 'absolute',
            top: '52.3%',
            left: '14%',
            width: '9%'
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            top: '54.3%',
            left: '17%',
            width: '8%'
        },
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            top: '56%',
            left: '16%',
            width: '8%'
        },
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            top: '60%',
            left: '20%'
        },
    },
    comingSoon: {
        [theme.breakpoints.up('xs')]: {
            width: '93%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '85%'
        },
        [theme.breakpoints.up('md')]: {
            width: '88%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '76%'
        },
    },
    profilePic: {
        [theme.breakpoints.up('xs')]: {
            borderRadius: `50%`,
            width: '15%',
            top: '35%',
            left: '10%',
            position: 'absolute',
        },
        [theme.breakpoints.up('sm')]: {
            borderRadius: `50%`,
            width: '15%',
            top: '33%',
            left: '13%',
            position: 'absolute',
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: `50%`,
            width: '15%',
            top: '33%',
            left: '12%',
            position: 'absolute',
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: `50%`,
            width: '15%',
            top: '15.5%',
            left: '17%',
            position: 'absolute',
        },
    }
}));

export default function SimpleBackdrop() {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Backdrop className={classes.backdrop} open={true} >
                <div
                    style={
                        {
                            textAlign: 'center',
                            justifyContent: 'center',
                            marginBottom: 0,
                        }
                    }
                >
                    <img src={profilePic} className={classes.profilePic} onClick={handleClick} />
                    <Button className={classes.btn} onClick={handleClick}>
                        Subscribe
                    </Button>
                    <img src={youtubeSubscribe} width='6%' className={classes.youtubeImage} onClick={event => window.open('https://www.youtube.com/channel/UC3p1LrTRYhvE2YitRm-kEig?sub_confirmation=1', '_blank')} />
                    <img src={comingSoonImg} className={classes.comingSoon} onClick={event => window.open('https://www.youtube.com/channel/UC3p1LrTRYhvE2YitRm-kEig?sub_confirmation=1', '_blank')} />
                    <Popover
                        classes={{ root: 'subscribe' }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <div>
                            <Subscribe />
                        </div>
                    </Popover>
                    <div
                        style={
                            {
                                textAlign: 'center',
                                justifyContent: 'center',
                                marginBottom: 0,
                            }
                        }
                    >
                    </div>
                </div>
            </Backdrop>
        </div>
    );
}
